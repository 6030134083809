// frontend/src/components/AlbumList.jsx

import React from 'react';
import { Typography } from 'antd';
import './AlbumList.css'; // Importér CSS for styling

const { Title, Paragraph } = Typography;

const AlbumList = () => {
  return (
    <div className="album-list-container">
      <img 
        src={`/bryllupsbilleder/forside/Bryllupsbilleder-0161.jpg`} 
        alt="Bryllupsbilleder-0161" 
        className="fullscreen-image"
      />
      
      <div className="overlay">
        <Title level={2} className="album-list-title">Cathrine og Niclas bryllup</Title>
        <Paragraph className="album-list-date">24. August 2024</Paragraph>
      </div>
    </div>
  );
};

export default AlbumList;
