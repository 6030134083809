// frontend/src/components/TopMenu.jsx

import React, { useEffect, useState } from 'react';
import { Menu, Spin, Alert } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './TopMenu.css'; // Importér den opdaterede CSS

const TopMenu = () => {
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    axios.get('/api/albums') // Brug relative sti til API
      .then(response => {
        // Filtrer "Forside" ud af albumslisten
        const filteredAlbums = response.data.filter(album => album.toLowerCase() !== 'forside');
        setAlbums(filteredAlbums);
        setLoading(false);
      })
      .catch(err => {
        setError('Kunne ikke hente albums');
        setLoading(false);
      });
  }, []);

  if (loading) return <Spin tip="Indlæser menu..." />;
  if (error) return <Alert message="Fejl" description={error} type="error" showIcon />;

  // Bestem hvilken menu-item der er valgt baseret på URL
  const pathParts = location.pathname.split('/');
  const selectedKey = pathParts[1] === 'album' ? decodeURIComponent(pathParts[2]) : 'home';

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[selectedKey]}
      className="custom-menu" /* Sørg for, at ingen inline-stile påvirker menu */
      style={{ background: 'transparent', zIndex: 3, position: 'absolute', top: 0, left: 0, width: '100%' }} /* Anvend inline-stil for at overstyre baggrund */
    >
      <Menu.Item key="home">
        <Link to="/">Forside</Link>
      </Menu.Item>
      {albums.map(album => (
        <Menu.Item key={album}>
          <Link to={`/album/${encodeURIComponent(album)}`}>{album}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default TopMenu;
